<script>
import {accountMutations, accountStore} from "@/state/modules/accountStoreHelper";
import axios from "axios";
import {msgModalOptCenter} from "@/components/globalFunctions";
import _ from "lodash";
import {allPerposeActions} from "@/state/modules/allPerposeStoreHelper";
import {loggedInfoObject} from "@/state/helpers";

export default {
  name: "friendsEditCategoryModal",

  data() {
    return {}
  },

  props: {
    editCategoryProp: {
      type: Object
    }
  },

  computed: {
    ...accountStore,
    ...loggedInfoObject,
    editCategoryData() {
      return _.cloneDeep(this.editCategoryProp)
    },
  },

  methods: {
    ...accountMutations,
    ...allPerposeActions,

    editTalkCategory(e) {
      if (this.editCategoryData.name === "") {
        e.preventDefault()
        this.$bvModal.msgBoxOk("카테고리 제목은 필수입니다.", msgModalOptCenter)
      } else {
        let url = `/api/category/update`

        console.log(this.editCategoryData)
        this.editCategoryData['market_srl'] = Number(this.logged_info.mart_srl)
        this.editCategoryData.parent_srl = Number(this.editCategoryData.parent_srl)
        this.editCategoryData.category_srl = Number(this.editCategoryData.category_srl)

        axios.put(
            url,
            this.editCategoryData,
            {
              headers: {
                accessToken: this.accountVuexInfo.accessToken,
                refreshToken: this.accountVuexInfo.refreshToken
              },
            })
            .then((res) => {
              console.log(res.data)
              if (res.data.status === 'success') {
                this.$emit('childReloadInfo')
              }
            })
            .catch((err) => {
              console.log("editPharmFriendCategory Error :", err)
              if (!_.isEmpty(err.response)) {
                if (err.response.status === 401) {
                  this.setCheckAuth(false)
                }
              }
            })
      }
    },
  }
}
</script>

<template>
  <b-modal
      centered
      id="friendsEditCategoryModal"
      :title="`카테고리 수정`"
      :ok-title="`확인`"
      :cancel-title="`취소`"
      @ok="editTalkCategory"
  >
    <div class="modal-body p-0">
      <div class="row align-items-center mx-0">
        <label class="col-md-3 col-form-label d-flex align-items-center">
          <em style="color: red">*&nbsp;</em>
          제목
        </label>
        <div class="col-md-9">
          <input
              class="form-control"
              type="text"
              placeholder="카테고리 제목"
              v-model="editCategoryData.name"
          />
          <!--              v-model="editCategory.title"-->
        </div>
      </div>

      <div v-if="false" class="row align-items-center mx-0">
        <label class="col-md-3 col-form-label d-flex align-items-center ">
          &nbsp;&nbsp;설명
        </label>
        <div class="col-md-9">
          <input
              class="form-control"
              type="text"
              placeholder="설명"
              v-model="editCategoryData.description"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style scoped>

</style>
