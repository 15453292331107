const categoryManagementField = [
    {
        key: "name",
        label: "제목"
    },
    // {
    //     key: "description",
    //     label: "설명"
    // },
    {
        key: "button1",
        label: "수정",
        tdClass: 'tdNonePadding'
    },
    {
        key: "button",
        label: "삭제",
        tdClass: 'tdNonePadding'
    },
    // {
    //     key: "button",
    //     label: "",
    //     tdClass: 'memberGroupButtonTdClass'
    // }
]

export {categoryManagementField}
