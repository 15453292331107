<script>
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page_header_new.vue";
import appConfig from "@/app.config";
import {allPerposeActions, allPerposeMutations, allPerposeStore} from "@/state/modules/allPerposeStoreHelper";
import {categoryManagementField} from "@/views/pages/categories/CategoryManagementField";
import {msgConfirmOptCenter, msgModalOptCenter} from "@/components/globalFunctions";
import axios from "axios";
import {accountMutations, accountStore} from "@/state/modules/accountStoreHelper";
import friendsAddCategoryModal from "@/views/pages/categories/modal/AddCategoryModal.vue";
import friendsEditCategoryModal from "@/views/pages/categories/modal/EditCategoryModal.vue";
import draggable from "vuedraggable";
import _ from "lodash";

export default {
  page: {
    title: "메뉴 카테고리 관리",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  data() {
    return {
      title: "메뉴 카테고리 관리",
      field: categoryManagementField,
      modalType: {},
    };
  },

  components: {
    friendsEditCategoryModal,
    friendsAddCategoryModal,
    Layout,
    PageHeader,
    draggable,
  },

  computed: {
    ...allPerposeStore,
    ...accountStore,
    boardCategoryLocalList: {
      get() {
        return _.cloneDeep(this.boardCategoryList)
      },
      set(value) {
        this.setBoardCategoryList(value)
      }
    },
  },

  mounted() {
    this.getBoardCategoryList()
  },

  methods: {
    ...allPerposeMutations,
    ...allPerposeActions,
    ...accountMutations,

    getCategoryList() {
      console.log("getCategoryList")
      this.getBoardCategoryList()
    },

    editTalkCategory(val) {
      console.log(val, "------")
      this.setBoardCategoryInfo(val)
      this.modalType = val
      this.$bvModal.show('friendsEditCategoryModal')
    },

    deleteTalkCategory(val) {
      console.log(val)

      this.$bvModal.msgBoxConfirm(`선택한 카테고리를 삭제하시겠습니까? `, msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              let url = `/api/category/delete/${val.category_srl}`

              axios.delete(url)
                  .then((res) => {
                    console.log(res.data)
                    if (res.data.status === 'success') {
                      this.getBoardCategoryList()
                    }
                  })
                  .catch((err) => {
                    console.log("deletePharmFriendCategory Error :", err)
                    if (!_.isEmpty(err.response)) {
                      if (err.response.status === 401) {
                        this.setCheckAuth(false)
                      }
                    }
                  })
            }
          })
    },

    editTalkCategoryData(val) {
      let url = `/api/postCategory/admin/update`

      console.log(url, val)

      axios.put(
          url,
          val,
          {
            headers: {
              accessToken: this.accountVuexInfo.accessToken,
              refreshToken: this.accountVuexInfo.refreshToken
            },
          })
          .then((res) => {
            console.log(res.data)
          })
          .catch((err) => {
            console.log("Error", err)
            if (!_.isEmpty(err.response)) {
              if (err.response.status === 401) {
                this.setCheckAuth(false)
              }
            }
          })
    },

    endCategoryDrag(evt) {
      console.log(evt)
      for (let i = 0; i < this.boardCategoryLocalList.length; i++) {
        this.boardCategoryLocalList[i].list_order = i + 1
      }
    },

    async saveCategoryOrder() {
      for (let i = this.boardCategoryLocalList.length - 1; i >= 0; i--) {
        await this.editTalkCategoryData(this.boardCategoryLocalList[i])
      }
      await this.$bvModal.msgBoxOk(`순서 저장 완료`, msgModalOptCenter)
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>

    <div class="card">
      <div class="card-header d-flex justify-content-between align-items-center">
        목록 <p class="m-0" v-if="false">순서는 마우스로 끌어서 조정가능</p>
        <b-button size="sm" class="btn btn-soft-primary" @click="$bvModal.show('friendsAddCategoryModal')">
          추가
        </b-button>
      </div>

      <div class="card-body">
        <div class="table-responsive mb-0">
          <div class="table-responsive">
            <table class="table mb-0 table-hover">
              <thead>
              <tr>
                <th v-for="row in field" :key="row.key">
                  {{ row.label }}
                </th>
              </tr>
              </thead>
              <draggable v-model="boardCategoryLocalList" tag="tbody" @end="endCategoryDrag">
                <tr v-for="row in boardCategoryLocalList" :key="row.category_srl">
                  <th scope="row">
                    {{ row.name }}
                  </th>

                  <td v-if="false">
                    {{ row.description }}
                  </td>

                  <td class="p-0" style="vertical-align: middle">
                    <b-button variant="link" @click="editTalkCategory(row)">수정</b-button>
                  </td>

                  <td class="p-0" style="vertical-align: middle">
                    <b-button variant="link" @click="deleteTalkCategory(row)">삭제</b-button>
                  </td>
                </tr>
              </draggable>
            </table>
          </div>
        </div>
      </div>

      <div v-if="false" class="card-footer d-flex justify-content-end">
        <b-button variant="primary" @click="saveCategoryOrder">순서 저장</b-button>
      </div>
    </div>

    <friends-add-category-modal @childReloadInfo="getCategoryList"></friends-add-category-modal>
    <friends-edit-category-modal :edit-category-prop="modalType"
                                 @childReloadInfo="getCategoryList"></friends-edit-category-modal>

  </Layout>
</template>

<!--
 <div class="table-responsive mb-0">
          <b-table
              striped
              :items="boardCategoryLocalList"
              :fields="field"
              responsive="sm"
          >
            <template #cell(button)="row">
              <b-button variant="link" @click="deleteTalkCategory(row.item)">삭제</b-button>
            </template>

            <template #cell(button1)="row">
              <b-button variant="link" @click="editTalkCategory(row.item)">수정</b-button>
            </template>
          </b-table>
        </div>
-->
